import docReady from '../../../assets/js/docReady.js';
import { clickableTable, scrollableTable } from '../../../assets/js/helpers/table.js';

docReady(() => {
    document.querySelectorAll('.flamingo-table-sim-only.table-container')
        .forEach((table) => {
            clickableTable(table);
            scrollableTable(table);
        });
});
